import 'react-phone-number-input/style.css';
import PhoneInputInternal, { FeatureProps } from 'react-phone-number-input';
import { forwardRef } from 'react';

export const VALID_PHONE_NUMBER_REGEX = /^(\+\d{7,15})$/;

interface PhoneInputProps {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: FeatureProps<unknown>['onBlur'];
  placeholder?: string;
  defaultCountry?: FeatureProps<unknown>['defaultCountry'];
  name?: string;
  id?: string;
  countrySelectProps?: Omit<PhoneInputProps, 'countrySelectProps'>;
  className?: string;
  disabled?: boolean;
}

export const PhoneInput = forwardRef(function PhoneInput(
  {
    value,
    onChange,
    onBlur,
    placeholder,
    defaultCountry = 'US',
    countrySelectProps,
    name,
    id,
    className,
    disabled = false,
  }: PhoneInputProps,
  ref
) {
  return (
    <PhoneInputInternal
      placeholder={placeholder}
      value={value || ''}
      onChange={(value) => onChange?.(value || '')}
      onBlur={onBlur}
      defaultCountry={defaultCountry}
      addInternationalOption={false}
      initialValueFormat="national"
      limitMaxLength
      name={name}
      countrySelectProps={countrySelectProps}
      id={id}
      disabled={disabled}
      ref={ref as any}
      className="rounded-md border border-soft-200 pl-2 ring-strong-400/25 ring-offset-2 ring-offset-white-0 focus-within:border-strong-950 focus-within:ring-2"
      numberInputProps={{
        className:
          'w-full input-padding placeholder-gray-400 transition duration-150 ease-in-out ml-1 border border-t-0 border-r-0 border-b-0 border-soft-200 rounded-md rounded-l-none shadow-sm appearance-none outline-none sm:text-sm sm:leading-5 bg-background text-strong-950 h-10 ' +
          (className || ''),
      }}
    />
  );
});
