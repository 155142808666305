import { forwardRef } from 'react';
import Spinner from 'components/icons/Spinner';
import classNames from 'classnames';

interface ButtonProps {
  title?: string;
  isLoading?: boolean;
  center?: boolean;
  right?: boolean;
  left?: boolean;
  fullWidth?: boolean;
  secondary?: boolean;
  customWidth?: string;
  disabled?: boolean;
  children?: JSX.Element;
}

// ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      isLoading = false,
      title,
      children,
      center,
      right,
      left,
      fullWidth,
      secondary,
      customWidth,
      disabled,
      onClick,
      className,
      style,
      ...buttonProps
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        onClick={disabled || isLoading ? () => {} : onClick}
        style={{ ...style, fontSize: '13px' }}
        className={classNames(
          className,
          'button-padding flex h-max items-center justify-center rounded-md border border-transparent transition duration-150 ease-in-out',
          {
            'cursor-not-allowed opacity-50': disabled,
            'cursor-pointer': !disabled,
            'mx-auto': center,
            'ml-auto': right,
            'mr-auto': left,
            'w-full': fullWidth,
            [`w-[${customWidth}]`]: customWidth,
            'text-white': !isLoading,
            'bg-slate-gray  hover:bg-gray-600 focus:border-hammr-orange-200 active:bg-gray-700':
              secondary,
            'bg-hammr-orange-600 hover:bg-hammr-orange-500 focus:border-gray-200 active:bg-hammr-orange-700':
              !secondary,
            'text-hammr-orange-600 hover:text-hammr-orange-500':
              !secondary && isLoading,
            'text-slate-gray hover:text-gray-600': secondary && isLoading,
          }
        )}
        disabled={disabled}
        {...buttonProps}
      >
        {isLoading && (
          <div className="absolute z-0">
            {secondary ? (
              <Spinner
                width="20"
                fill="black"
                className="mx-auto animate-spin"
              />
            ) : (
              <Spinner
                width="20"
                fill="white"
                className="mx-auto animate-spin"
              />
            )}
          </div>
        )}
        {children}
        <div style={{ minHeight: '20px' }} className="capitalize-first w-full">
          {title}
        </div>
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
