import { ResolverResult } from 'react-hook-form';
import * as yup from 'yup';

export const yupResolver =
  <T>(validationSchema: yup.ObjectSchema<T>) =>
  async (data: T): Promise<ResolverResult<T>> => {
    try {
      const values = await validationSchema.validate(data, {
        abortEarly: false,
      });

      return {
        values,
        errors: {},
      } as ResolverResult<T>;
    } catch (errors) {
      return {
        values: {},
        errors: errors.inner.reduce(
          (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? 'validation',
              message: currentError.message,
            },
          }),
          {}
        ),
      } as ResolverResult<T>;
    }
  };
