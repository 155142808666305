import Link from 'next/link';
import Image from 'next/image';
import Button from 'components/elements/Button';

const Header = (): JSX.Element => {
  return (
    <header className="m-auto text-gray-700 body-font max-w-7xl">
      <div className="container flex flex-row flex-wrap items-center p-5 mx-auto">
        <Link href="/">
          <a className="flex items-center mb-4 font-medium text-gray-900 title-font md:mb-0">
            <Image
              width={121}
              height={32}
              className="w-auto h-8 mt-3 md:mt-0 sm:h-9"
              src="/img/hammr-logo.png"
              alt="Hammr Inc."
            />
          </a>
        </Link>
        <nav className="flex flex-wrap items-center justify-center text-base mr-auto md:ml-4 md:py-1 md:pl-4"></nav>
        <div>
          {/* <Link href="/login">
            <div className="inline-flex">
              <Button title="Login" secondary={true} />
            </div>
          </Link> */}
          {process.env.NEXT_PUBLIC_ALLOW_SIGNUP === 'true' && (
            <Link href="/signup">
              <div className="inline-flex ml-4">
                <Button title="Sign Up" />
              </div>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
