import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

import { useAuth } from 'hooks/useAuth';
import { Button } from '@hammr-ui/components/button';
import { PhoneInput } from 'components/shared/PhoneInput';
import * as yup from 'yup';
import { yupResolver } from 'utils/yupResolver';
import { showErrorToast } from 'utils/errorHandling';

interface LoginData {
  phone: string;
}

const formSchema = yup.object<LoginData>().shape({
  phone: yup.string().required('Please enter a phone number'),
});

const LoginForm: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginData>({
    defaultValues: {
      phone: '',
    },
    resolver: yupResolver(formSchema),
  });
  const { push } = useRouter();
  const { getOtp } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loginReqTimestamp, setLoginReqTimestamp] = useState<dayjs.Dayjs>(null);

  const makeLoginReq = async (data: LoginData, timestamp: dayjs.Dayjs) => {
    try {
      await getOtp(data);
      setLoginReqTimestamp(timestamp);

      push('/verify-otp');
    } catch (err) {
      showErrorToast(err.message || 'Failed to login. Please try again.', 'Login failed');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (data: LoginData) => {
    setIsLoading(true);
    setError(null);

    // current timestamp
    const currentTimestamp = dayjs();

    // first load / null
    if (!loginReqTimestamp) {
      // get otp code, then redirect to /verity-otp
      makeLoginReq(data, currentTimestamp);
    }

    // if loginReqTimestamp is not null
    if (loginReqTimestamp) {
      // add 30 seconds to loginReqTimestamp
      const elapsedTimeThreshold = loginReqTimestamp.add(30, 'second');

      if (currentTimestamp.isAfter(elapsedTimeThreshold)) {
        // get otp code, then redirect to /verity-otp
        makeLoginReq(data, currentTimestamp);
      } else {
        // set error
        setIsLoading(false);
        showErrorToast('Please wait 30 seconds before retrying', 'Too many requests');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      {error?.message && (
        <div className="rounded border border-dashed border-error-base bg-error-base/10 p-2 text-center text-error-base">
          <span>{error.message}</span>
        </div>
      )}
      <div className="rounded-md">
        <label htmlFor="phone" className="block text-sm font-medium leading-5 text-strong-950">
          Phone Number
        </label>
        <div className="mt-1 rounded-md">
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <PhoneInput
                id="phone"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
          {errors.phone && <div className="mt-2 text-xs text-red-600">{errors.phone.message}</div>}
        </div>
      </div>

      <Button
        type="submit"
        loading={isLoading}
        loadingText="Getting OTP..."
        fullWidth
        variant="default"
        disabled={isLoading}
      >
        Get OTP Code
      </Button>
    </form>
  );
};

export default LoginForm;
